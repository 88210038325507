/* eslint-disable camelcase */
import React from 'react';
import Modal from 'components/common/Modal/Modal';
import classNames from 'classnames';
import moment from 'moment';
import style from './style.module.scss';

const PropertyModal = ({
  isOpen,
  onClose,
  doc_type,
  selectedDocObj,
  handleButtonClick,
  onSendClick,
  expires_at,
  issue_date,
  doc_file,
  handleFileChange,
  handleExpiryChange,
  handleIssueDate,
  setDocTypeId,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <h2 style={{ borderBottom: '0.012em solid #AD8C63', padding: '10px 0' }}>
      {`Upload ${doc_type}`}
    </h2>
    <form style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="form-group">
        <label htmlFor="document-type">Type of document</label>
        <select
          disabled
          className="form-control"
          id="document-type"
          onChange={(e) => setDocTypeId(e.target.value)}
          value={doc_type}
        >
          <option value="Passport">Passport</option>
          <option value="Title Deed">Title Deed</option>
          <option value="DEWA Bill">DEWA Bill</option>
          <option value="NOC">NOC</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="document">Select Document</label>
        {(doc_file && doc_file?.name) || selectedDocObj?.file_name ? (
          <p style={{ fontSize: '12px', margin: 'unset', color: '#AD8C63' }}>
            {`${doc_file?.name || selectedDocObj?.file_name}`}
          </p>
        ) : (
          <></>
        )}
        <button
          type="button"
          className={classNames('file-input-button', 'form-control')}
          onClick={handleButtonClick}
        >
          <input
            id="document"
            type="file"
            hidden
            onChange={handleFileChange}
            accept="image/*, application/pdf"
          />
          Choose file
        </button>
      </div>

      {!['NOC', 'Title Deed', 'DEWA Bill'].includes(doc_type) && (
        <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="expiry-date">Select expiry date</label>
          <input
            type="date"
            id="expiry-date"
            className="form-control"
            onChange={handleExpiryChange}
            value={expires_at}
            min={moment().format('YYYY-MM-DD')}
          />
        </div>
      )}

      {/* Issue date for DEWA Bill */}
      {doc_type === 'DEWA Bill' && (
        <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="issue-date">Select Issue Date</label>
          <input
            type="date"
            id="issue-date"
            className="form-control"
            onChange={handleIssueDate}
            value={issue_date}
            min={moment().subtract(50, 'days').format('YYYY-MM-DD')}
            max={moment().format('YYYY-MM-DD')}
          />
        </div>
      )}

      {/* Read only Expiry date for DEWA Bill */}
      {doc_type === 'DEWA Bill' && (
        <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="expiry-date">Expiry date</label>
          <input
            type="date"
            id="expiry-date"
            className="form-control"
            onChange={handleExpiryChange}
            value={expires_at}
            readOnly
          />
        </div>
      )}

      <button
        type="button"
        disabled={
          (!doc_file && !selectedDocObj?.file_name) ||
          (!['NOC', 'Title Deed'].includes(selectedDocObj?.doc_type) && !expires_at)
        }
        onClick={onSendClick}
        className={`btn ${style['upload-doc-btn']}`}
      >
        Submit
      </button>
    </form>
  </Modal>
);

export default PropertyModal;
