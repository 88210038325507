/* eslint-disable complexity */
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import style from '../style.module.scss';
import UtilityTitle from './UtilityDetails';

export const FullGrid = ({ children, gridTemplateColumns = '' }) => (
  <div
    className={classNames(style.row, style.utilitiesDetailsWrapper)}
    style={{ gridTemplateColumns }}
  >
    {children}
  </div>
);

const BankDetails = ({ title, summary, name, fullRow = false }) => {
  const { t } = useTranslation('form');
  const data = summary?.bankData?.[name] || '';
  if (!data) return <></>;

  if (!fullRow) {
    return (
      <div className={classNames(style.col)}>
        <UtilityTitle title={t(title)} />
        <div>
          <span>{data || '-'}</span>
        </div>
      </div>
    );
  }

  return (
    <FullGrid gridTemplateColumns={fullRow ? 'auto' : 'repeat(auto-fill, minmax(280px, 1fr))'}>
      <div className={classNames(style.col)}>
        <UtilityTitle title={t(title)} />
        <div>
          <span>{data || '-'}</span>
        </div>
      </div>
    </FullGrid>
  );
};

export default BankDetails;
