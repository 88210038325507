import React, { useCallback, useEffect, useState } from 'react';

import './EditProfile.scss';
import classNames from 'classnames';
import swal from 'sweetalert';
import cloneDeep from 'lodash/cloneDeep';
import CustomForm from 'components/CustomForm/CustomForm';
import { getSurveyGetV2, saveSurveyV2 } from 'services/surveyService';
import { authService } from 'services/authService';
import { useLoader } from 'components/common/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { hasDataBeenChanged, prepareFormToSave } from './helpers';

const EditProfile = (props) => {
  const {
    initializeProfile,
    setPageTitle,
    setBackVisibility,
    personalDetails,
    match: {
      url,
      params: { step, propertyId },
    },
  } = props;
  const [formData, setFormData] = useState(null);
  const [currentPage, setCurrentPage] = useState(step ? parseInt(step, 10) : 1);
  const [currentSectionType, setCurrentSectionType] = useState(null);
  const [formDataDownloaded, setFormDataDownloaded] = useState(false);
  const [isRussian, setIsRussian] = useState(false);
  const [property, setProperty] = useState(null);
  const { setLoaderVisibility } = useLoader();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    try {
      swal.close();
    } catch (err) {
      //
    }
    if (!step && !url.includes('personal-survey')) {
      history.push(`/settings/summary/${propertyId}`);
    }
  }, [url, history, step]);

  useEffect(() => {
    setBackVisibility(true);
    if (initializeProfile) {
      setPageTitle(t('form:onboardingFrom'));
    }
  }, [initializeProfile, t]);

  const getFormData = useCallback(async () => {
    setLoaderVisibility(true);
    const getSurveyV2Result = await getSurveyGetV2({
      ownerId: authService.currentUser.userId,
      propertyId,
    });

    setProperty(getSurveyV2Result?.data?.property);
    setFormData({
      sections: getSurveyV2Result?.data?.surveyData.sections,
    });
    setIsRussian(getSurveyV2Result?.data?.surveyData?.isRussian);

    setLoaderVisibility(false);
    setFormDataDownloaded(true);
  }, [propertyId, setLoaderVisibility]);

  useEffect(() => {
    getFormData();
  }, [getFormData]);

  const updateFormData = (updatedSections) => {
    const [updatedSection] = updatedSections;
    const newSections = formData.sections.map((section) => {
      if (section.id === updatedSection.id) {
        return cloneDeep(updatedSection);
      }

      return cloneDeep(section);
    });

    setFormData({ sections: newSections });
  };

  const submitCurrentSection = async (form, lastStep, canBeChanged, finished) => {
    const sections = prepareFormToSave(form);
    const [section] = sections;

    if (canBeChanged && hasDataBeenChanged(section, formData)) {
      await submitSection(form, lastStep, finished, section);
      updateFormData(form);
    }

    return true;
  };

  const submitSection = async (form, lastStep, finished, section) => {
    setLoaderVisibility(true);
    const sections = prepareFormToSave(form);
    const saveResult = await saveSurveyV2({
      ownerId: authService.currentUser.userId,
      propertyId,
      sections,
      ...(finished ? { completed: finished } : {}),
    });
    history.push(`/settings/${section?.personalDetails ? 'list' : 'summary'}/${propertyId}/`);
    setLoaderVisibility(false);

    return saveResult;
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className={classNames('edit-profile account-overflow property-overflow')}>
      {!isRussian || personalDetails ? (
        <>
          {currentSectionType && (
            <h1 className="edit-profile-header">
              <span
                className={classNames('edit-profile-header__title', {
                  property: currentSectionType === 'propertyDetails' && property,
                })}
              >
                {t(`form:${currentSectionType}`)}
              </span>
              {property && currentSectionType === 'propertyDetails' && (
                <div className="edit-profile-header__property-name">{property.nickname}</div>
              )}
            </h1>
          )}

          {currentPage === 1 && initializeProfile ? (
            <div className="edit-profile__heading">
              <p>{t('user:editProfile1stLine')}</p>
              <p>{t('user:editProfile2ndLine')}</p>
              <p>{t('user:editProfile3rdLine')}</p>
            </div>
          ) : null}
          {formDataDownloaded ? (
            <CustomForm
              {...props}
              sectionTypeChanged={(section) => setCurrentSectionType(section)}
              formData={formData}
              initPage={currentPage}
              pageChange={onPageChange}
              submitCurrentSection={submitCurrentSection}
            />
          ) : null}
        </>
      ) : (
        /* TEMPORARY SOLUTION - there will be another form */
        <div className="thank-you-page">
          <h1 className="edit-profile-header">{t('comingSoon')}</h1>
          <p>{t('comingSoonText')}</p>
        </div>
      )}
    </div>
  );
};

export default EditProfile;
