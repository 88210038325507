import React from 'react';
import './Modal.scss'; // Import the CSS file for styling

const Modal = ({ isOpen, onClose, children }) => {
  const closeModal = () => {
    onClose();
  };

  // For closing Modal on outside click
  const handleOverlayClick = () => {
    // if (e.target.classList.contains('modal-overlay')) {
    //   closeModal();
    // }
  };

  return (
    isOpen && (
      <div className="modal-overlay" onClick={handleOverlayClick}>
        <div className="custom-modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            {children}
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
