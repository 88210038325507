import Axios from 'axios';
import download from 'downloadjs';
import environment from '../environments/environment';
import { documentsService } from './documentsService';

/// Getting Uploaded Documents List
export const getDocList = (props) =>
  new Promise((resolve) => {
    getDocListGet(props)
      .then((item) => {
        if (item.data) {
          resolve({ docList: item?.data?.owner_documents, success: true });
        } else {
          resolve({ docList: [], success: false });
        }
      })
      .catch(() => {
        resolve({ docList: [], success: false });
      });
  });

/// Download Document API
export function getDocumentFile(url, name) {
  return new Promise((resolve, reject) => {
    downloadSingleDocument(url)
      .then((response) => {
        const blob = new Blob([response?.data]);

        if (typeof window.cordova !== 'undefined') {
          documentsService.saveBlob2File(`${name}`, blob);
          resolve();

          return;
        }

        const content = response.headers['content-type'];
        download(response.data, `${name}`, content);
        resolve();
      })
      .catch((error) => reject(error));
  });
}

/// Download Utility Doc
export const getContent = ({ docId, doc_filename: docFileName }) =>
  new Promise((resolve) => {
    getBillDoc(docId)
      .then((response) => {
        const blob = new Blob([response?.data]);

        if (typeof window.cordova !== 'undefined') {
          documentsService.saveBlob2File(`${docFileName}`, blob);
          resolve();

          return;
        }

        const content = response.headers['content-type'];

        download(response.data, `${docFileName}`, content);
        resolve();
      })
      .catch(() => {
        resolve({ docList: [], success: false });
      });
  });

/// Download NOC API
export function getNocForm() {
  return new Promise((resolve, reject) => {
    downloadNoc()
      .then((response) => {
        const fileName = 'FrankPorter_DET_NOC.pdf';
        const blob = new Blob([response?.data]);

        if (typeof window.cordova !== 'undefined') {
          documentsService.saveBlob2File(fileName, blob);
          resolve();

          return;
        }

        const content = response.headers['content-type'];
        download(response.data, fileName, content);
        resolve();
      })
      .catch((error) => reject(error));
  });
}

/// UPLOAD Document API
export const saveUploadedDocument = (props) =>
  new Promise((resolve) => {
    uploadDocument(props)
      .then((item) => {
        if (item.data) {
          resolve(item.data);
        } else {
          resolve(null);
        }
      })
      .catch(() => {
        resolve(null);
      });
  });

const getDocListGet = ({ ownerId, propertyId }) =>
  Axios.get(`${environment.ownerApi}/owner_documents_list/`, {
    params: { ownerId, propertyId },
  });

const getBillDoc = (id) =>
  Axios.get(`${environment.ownerApi}/owner_bill_document/`, {
    params: { id },
    responseType: 'blob',
  });

const downloadSingleDocument = (id) =>
  Axios.get(`${environment.ownerApi}/owner_single_document/`, {
    params: { id },
    responseType: 'blob',
  });

const downloadNoc = () =>
  Axios.get(`${environment.ownerApi}/owner_noc/`, {
    responseType: 'blob',
  });

const uploadDocument = (formData) =>
  Axios.post(`${environment.ownerApi}/owner_document/`, formData);
