/* eslint-disable camelcase */
import React from 'react';
import Button from 'components/common/Button';
import moment from 'moment';
import cn from 'classnames';
import _ from 'lodash';

import style from '../style.module.scss';

export const DocDetails = ({
  docDetails,
  shouldHideExpiry = false,
  onUploadClick,
  onDownloadClick,
  isDownloading,
  getNocForm,
}) => {
  if (!docDetails) return <></>;
  const { expires_at, file, doc_type, file_name } = docDetails;

  const isDocExpiring = (expiryDate) => {
    const parsedExpiryDate = moment(expiryDate, 'YYYY-MM-DD').startOf('day');
    const currentDate = moment().startOf('day');
    const differenceInDays = parsedExpiryDate.diff(currentDate, 'days');
    const expiredMessage = `${_.capitalize(doc_type)} expired, please upload the document`;

    let text;
    if (!expiryDate || differenceInDays > 60) {
      text = '';
    } else if (differenceInDays >= 0 && differenceInDays <= 60) {
      text = `${_.capitalize(doc_type)} is Expiring ${
        differenceInDays < 1
          ? 'today'
          : `in ${differenceInDays} ${differenceInDays === 1 ? 'day' : 'days'}`
      }`;
    } else if (doc_type === 'DEWA Bill') {
      const passedThreeMonths = currentDate.diff(parsedExpiryDate, 'days') >= 50;
      if (passedThreeMonths) {
        text = expiredMessage;
      }
    } else {
      text = expiredMessage;
    }

    return text;
  };

  return (
    <>
      <div className={style['doc-details-container']}>
        {shouldHideExpiry === false && (
          <>
            <li className={cn('utlity-detail-title', `${style['expiry-date']}`)}>Expiry Date</li>
            <p className={style['expiry-date-value']}>{expires_at || '-'}</p>
          </>
        )}

        {shouldHideExpiry !== false && doc_type !== 'Title Deed' && (
          <p className={style['download-link']} onClick={getNocForm}>
            Download NOC Form
          </p>
        )}
        {!['NOC', 'Title Deed'].includes(doc_type) && (
          <>
            <li className={cn('utlity-detail-title', style['note-title'])}>Note</li>
            <p className={style['note-value']}>{isDocExpiring(expires_at) || '-'}</p>
          </>
        )}

        {file_name && (
          <>
            <li className={cn('utlity-detail-title', `${style['expiry-date']}`)}>Document </li>
            <p className={style['expiry-date-value']}>{file_name || '-'}</p>
          </>
        )}
      </div>
      <div className={style['footer-buttons-container']}>
        <Button
          className={style['document-footer-btn']}
          variant="filled"
          value={file ? 'Update' : 'Upload'}
          onClick={() => onUploadClick()}
        />
        {file ? (
          <Button
            className={cn(style['document-footer-btn'])}
            variant="outline"
            value={isDownloading ? 'Downloading' : 'Download'}
            onClick={() => onDownloadClick()}
            disabled={isDownloading}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
