import { getSurveySummary as getSurveySummaryService } from 'services/surveyService';

import {
  BankDetails,
  GetSurveySummaryParams,
  GetSurveySummaryResult,
  Property,
  PropertyBankAccount,
  PropertyDetails,
  PropertyDetailsFinancial,
  SurveySummary,
  UseSurvey,
  UtilitiesDetails,
} from './models';

export const useSurvey: UseSurvey = () => {
  const getSurveySummary = async ({ propertyId }: GetSurveySummaryParams) => {
    const survey = await getSurveySummaryService({ propertyId });

    return survey as GetSurveySummaryResult;
  };

  const mapBankDetailsObject = (bankData?: PropertyBankAccount): BankDetails => {
    if (!bankData) {
      return {};
    }

    return {
      accountName: bankData.account_owner_name,
      bankName: bankData.account_bank_name,
      iban: bankData.account_iban,
      accountNumber: bankData.account_data,
      swift: bankData.account_swift,
      address: bankData.account_address,
      currency: bankData.account_currency,
      sortCode: bankData.account_sort_code,
      routeCode: bankData.account_route_code,
    };
  };

  const mapUtilitiesDetailsObject = (
    details?: PropertyDetailsFinancial
  ): UtilitiesDetails | null => {
    const internet = details?.expenses?.find(({ utility }) => utility === 'internet');
    const eow = details?.expenses?.find(({ utility = '' }) => utility?.startsWith('electricity'));
    const chiller = details?.expenses?.find(({ utility }) => utility === 'chiller');
    const gas = details?.expenses?.find(({ utility }) => utility === 'gas');
    const other = details?.expenses?.filter(({ utility }) => utility === 'other');

    return {
      eow,
      internet,
      chiller,
      gas,
      other,
    };
  };

  const mapPropertyDetailsObject = (property: Property): PropertyDetails | null => ({
    nickname: property.nickname,
    address: property.address,
    address_2: property.address_2,
    parkingNumber: property.parking,
    permit: property.dtcm_unique_code,
    permitExpiry: property.permit_expires_at,
    propertyCode: property.property_code,
    wifiName: property.wifi_name,
    wifiPass: property.wifi_pass,
  });

  const mapSurveyForSummary = (data: GetSurveySummaryResult): SurveySummary => ({
    bankData: mapBankDetailsObject(data.propertyBankAccount),
    ...mapUtilitiesDetailsObject(data.details),
    ...mapPropertyDetailsObject(data.property),
  });

  return { getSurveySummary, mapSurveyForSummary };
};
