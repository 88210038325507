export default class DummyUtilityData {
  constructor() {
    this.emptyOtherObj = this.provideEmptyObj({ title: 'Other', options: [] });

    this.emptyChillerObj = this.provideEmptyObj({ title: 'Chiller', options: [] });

    this.emptyGasObj = this.provideEmptyObj({ title: 'Gas', options: [] });

    this.emptyInternetObj = this.provideEmptyObj({ title: 'Internet', options: [] });

    this.emptyEowObj = this.provideEmptyObj({
      title: 'Electricity / Water',
      options: [],
    });
  }

  provideEmptyObj(objToAdd) {
    return {
      ...objToAdd,
      account_number: '',
      paid_by: '0',
      isBill: '',
      bill: undefined,
      email: '',

      charge: '1', /// // new obj
      docId: undefined,
      doc_filename: undefined,
      expenseName: '',
      expense_list_id: '',
      is_file: false,
      is_link: false,
      bill_link: undefined,
      link_id: undefined,
      // isEmail: false,
      login: '',
      pass: '',
      property_id: '',
      utility: '',
      id: '',
      value: '',
    };
  }
}
