/* eslint-disable camelcase */
import React, { Component } from 'react';
import { authService } from '../../services/authService';
import { userService } from '../../services/userService';

export class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotifications: false,
      notifications: [],
      openedNotification: null,
      modalIsOpen: false,
    };
    this.getNotifications();
  }

  onBellClick() {
    this.setState((prevState) => ({
      showNotifications: !prevState.showNotifications,
    }));
  }

  onNotificationClick(notification) {
    this.setState({
      showNotifications: false,
      modalIsOpen: true,
      openedNotification: notification,
    });
    if (notification.read_at) {
      return;
    }
    userService.markNotificationAsRead(authService.currentUser.userId, notification.id).then(() => {
      const newArr = this.state.notifications.slice();
      const foundNotification = newArr.find((x) => x.id === notification.id);
      if (foundNotification) {
        foundNotification.read_at = new Date();
        this.setState({ notifications: newArr });
      }
    });
  }

  onCloseModalClick() {
    this.setState({ modalIsOpen: false, openedNotification: null, showNotifications: false });
  }

  getNotifications() {
    userService.getUserNotifications(authService.currentUser.userId).then((notifications) => {
      if (notifications) {
        const notificationSorted = notifications.sort(
          (a, b) =>
            (a.read_at !== null) - (b.read_at !== null) ||
            new Date(b.created_at) - new Date(a.created_at)
        );
        this.setState({ notifications: notificationSorted });
      }
    });
  }

  unreadNotifications() {
    return this.state.notifications.filter((notification) => !notification.read_at);
  }

  renderContent() {
    const mapInCompleteProperties = JSON.parse(this.state.openedNotification?.content);

    const isPersonalSurveyValid =
      mapInCompleteProperties?.personal_data &&
      Object.entries(mapInCompleteProperties?.personal_data)?.every(([, value]) => !!value);

    return (
      <div>
        {!isPersonalSurveyValid ? (
          <div>
            {this.props.t('properties:uncompletedSurveysPersonalText')}
            <br />
            <p className="alert-property-item">
              <a onClick={this.onCloseModalClick.bind(this)} href="/#/settings/personal-survey">
                {this.props.t('user:editProfile')}
              </a>
            </p>
          </div>
        ) : (
          <div>
            {this.props.t('properties:uncompletedSurveysText')}
            <br />
            {Array.isArray(mapInCompleteProperties?.properties_data) &&
              mapInCompleteProperties?.properties_data.map(({ nickname, property_id }) => (
                <p className="alert-property-item" key={property_id}>
                  <a
                    onClick={this.onCloseModalClick.bind(this)}
                    href={`/#/settings/summary/${property_id}`}
                  >
                    {nickname}
                  </a>
                </p>
              ))}
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="notifications">
        <div onClick={this.onBellClick.bind(this)}>
          <i className="icon-bell" />
          <div className="unread-notifications">{this.unreadNotifications().length}</div>
        </div>
        {this.state.showNotifications && (
          <div className="overlay" onClick={this.onBellClick.bind(this)} />
        )}
        <div
          className={`dropdown-menu dropdown-menu-right${
            this.state.showNotifications ? ' show' : ''
          }`}
        >
          <div className="text-center dropdown-header">
            <strong>
              {this.props.t('unreadMessages', {
                quantity: this.unreadNotifications().length,
              })}
            </strong>
          </div>
          {this.state.notifications.map((notification) => (
            <button
              key={notification.id}
              type="button"
              onClick={this.onNotificationClick.bind(this, notification)}
              className={`dropdown-item${notification.read_at ? '' : ' unread'}`}
            >
              {notification?.is_survey === '1' ? 'Incomplete Survey' : notification.content}
            </button>
          ))}
        </div>
        {this.state.modalIsOpen && this.state.openedNotification ? (
          <div>
            <div className="modal-overlay notification-modal-overlay" />
            <div className="notification-modal">
              {this.state.openedNotification.is_survey === '1'
                ? this.renderContent()
                : this.state.openedNotification.content}
              <div className="button-container">
                <button onClick={this.onCloseModalClick.bind(this)} className="btn" type="button">
                  {this.props.t('close')}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}
