import React from 'react';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as NextIcon } from 'assets/error_icon.svg';

import './Textbox.scss';

const Textbox = ({ index, fieldState, name, handleChange, disabled, errorMessage }) => {
  const { t } = useTranslation('form');
  const description = t(`${name}Description`, { defaultValue: null });
  const error = errorMessage || '';

  return (
    <div className="textbox form-group">
      <label htmlFor={name}>{`${index}. ${t(name)}`}</label>
      {description ? <p className="textbox__description">{description}</p> : null}
      <input
        className="form-control"
        name={name}
        id={name}
        value={fieldState}
        onChange={handleChange}
        disabled={disabled}
      />
      {error && <span style={{ fontSize: '14px', color: 'red' }}>{error}</span>}
    </div>
  );
};

export default Textbox;
