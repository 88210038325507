/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import '../../EditProfile/EditProfile.scss';
import classNames from 'classnames';
import swal from 'sweetalert';
import UtilityForm from 'components/UtilityForm';
import { PROPERTY } from 'helpers/const';

const UploadUtility = (props) => {
  const property = JSON.parse(localStorage.getItem(PROPERTY));
  useEffect(() => {
    try {
      getFormData();
      swal.close();
    } catch (err) {
      //
    }
  }, []);

  return (
    <div className={classNames('edit-profile account-overflow property-overflow')}>
      <h1 className="edit-profile-header">
        <span className={classNames('edit-profile-header__title')}>Utility Details</span>
        {property && <div className="edit-profile-header__property-name">{property?.nickname}</div>}
      </h1>

      <UtilityForm {...props} />
    </div>
  );
};

export default UploadUtility;
