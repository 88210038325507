import React from 'react';

const UtilityCheckbox = ({
  name,
  checked,
  disabled = false,
  value,
  type = 'radio',
  onChange,
  id = '',
  label = '',
}) => {
  if (!name || !value) return <></>;

  return (
    <div className="form-check">
      <input
        className="form-check-input"
        name={name}
        type={type}
        onChange={onChange}
        id={id || name}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={id || name} className="form-check-label">
        {label || value}
      </label>
    </div>
  );
};

export default UtilityCheckbox;
