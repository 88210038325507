/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
import _, { cloneDeep } from 'lodash';
import swal from 'sweetalert';
import { authService } from 'services/authService';
import { PROPERTY_ID } from 'helpers/const';
import { getFieldName } from '../../helpers/fieldHelper';

export const getDefaultFields = (formData) => {
  const result = {};
  formData.forEach((section) => {
    const sectionCamel = getFieldName(section.name);
    result[sectionCamel] = result[sectionCamel] || {};
    section.fields.forEach(({ type, multipleFields, name, value }) => {
      result[sectionCamel][getFieldName(name)] =
        type === 'checkbox' || type === 'boolean' || multipleFields
          ? Array.isArray(value)
            ? value
            : multipleFields
            ? ['']
            : []
          : type === 'number'
          ? value || 0
          : value || '';
    });
  });

  return result;
};

export const getAdditionalFields = (form) => {
  const result = {};

  form.forEach((section) => {
    // const filledSection = getSectionById(formDataValues, section.id);
    const sectionCamel = getFieldName(section.name);
    result[sectionCamel] = result[sectionCamel] || {};
    section.fields.forEach(({ name, value, multipleFields }) => {
      // const { value } = getFieldById(filledSection?.fields, field.id) || {};
      result[sectionCamel][getFieldName(name)] = multipleFields
        ? Array.isArray(value)
          ? value.length
          : 1
        : 1;
    });
  });

  return result;
};

export const getSectionById = (sections, id) =>
  sections?.find(({ id: sectionId }) => sectionId === id);

// const getFieldById = (fields, id) =>
//   fields?.find(({ id: fieldId }) => fieldId === id);

export const connectFormDataWithTemplate = (formDataFilled, formDataSections) =>
  formDataSections.map((section) => {
    const sectionCamel = getFieldName(section.name);
    const filledSection = formDataFilled[sectionCamel];

    const fields = section.fields.map((field) => {
      const fieldCamel = getFieldName(field.name);
      const filledField = filledSection[fieldCamel];

      return { ...field, value: filledField };
    });

    return { ...section, fields };
  });

export const mapUtilitiesDetailsObject = (expenses) => {
  const internet = expenses?.find(({ utility }) => utility === 'internet');
  const eow = expenses?.find(({ utility = '' }) => utility?.startsWith('electricity'));
  const chiller = expenses?.find(({ utility }) => utility === 'chiller');
  const gas = expenses?.find(({ utility }) => utility === 'gas');
  const other = expenses?.filter(({ utility }) => utility === 'other');

  return {
    'electricity/water': eow,
    internet,
    chiller,
    gas,
    other,
  };
};

export const paidByOptions = [
  { value: '0', label: 'Frank Porter' },
  { value: '1', label: 'Owner' },
];

export const setDataAsPerUtility = (key, ...args) => {
  const [prevData, apiResponse, expensesSelect] = args || [];
  if (!apiResponse || !apiResponse?.[key]) return prevData?.[key];

  return {
    ...prevData?.[key],
    ...apiResponse?.[key],
    expenseName: apiResponse?.[key]?.expenseName || 'Not set',
    expense_list_id: apiResponse?.[key]?.expense_list_id || '0',
    options: handleExpenseOptions(expensesSelect?.[key]),
  };
};

export const handleExpenseOptions = (optionsObj) => {
  if (!optionsObj) return [];

  return Object.values(optionsObj).map(({ expense_id, expense_name }) => ({
    value: `${expense_id}-${expense_name}`,
    label: expense_name,
  }));
};

export const filterEowOPtions = (summaryInfo, eowProviders) =>
  eowProviders.filter(({ value }) => {
    const isStartWithAUH =
      summaryInfo?.propertyCode?.startsWith('AUH') || summaryInfo?.nickname?.startsWith('AUH');

    return !(!isStartWithAUH && value === 'ADDC');
  });

const mainUtilityKeys = ['internet', 'electricity/water', 'chiller', 'gas'];
const mainUtilitiesObjectKeys = [
  'id',
  'property_id',
  'expenseId',
  'expense_list_id',
  'expenseName',
  'account_number',
  'paid_by',
  'web_link',
  'web_pass',
  'login',
  'pass',
  'isEmail',
  'email',
  'is_file',
  'is_link',
  'utility',
];

const appendFiles = (valueArr, formData, guestyId) => {
  const duplicateFormData = formData;

  valueArr.forEach((obj, idx) => {
    if (obj?.bill) {
      duplicateFormData.append(`${idx}-${guestyId}`, obj?.bill);
    }
  });

  return duplicateFormData;
};

const appendOtherUtility = (valueArr, formData, guestyId) => {
  let duplicateFormData = formData;
  duplicateFormData = appendFiles(valueArr, duplicateFormData, guestyId);

  const newArr = valueArr.map((obj) => {
    const { bill, options, title, email, isBill, is_file, is_link, ...restObj } = obj;

    if (bill) {
      restObj.is_file = true;
      restObj.is_link = false;
    }

    if (obj?.bill_link && !bill) {
      restObj.is_file = false;
      restObj.is_link = true;
    }

    if (
      (!bill && obj?.doc_filename && !obj?.bill_link) ||
      (!bill && !obj?.bill_link) ||
      obj?.paid_by === '1'
    ) {
      restObj.is_file = false;
      restObj.is_link = false;
    }

    return restObj;
  });

  duplicateFormData.append('other', JSON.stringify(newArr));

  return duplicateFormData;
};

const appendUtility = (valueObj, formData, utilityKey) => {
  const obj = {};
  if (utilityKey !== 'other') {
    Object.entries(valueObj).forEach(([key, value]) => {
      if (mainUtilitiesObjectKeys.includes(key)) {
        if (key === 'email') obj.isEmail = !!value;
        obj[key] = value || '';
      }
    });
  }
  formData.append(utilityKey, JSON.stringify(obj));

  return formData;
};

export const prepareUtilityPayload = (utilityData, guestyId) => {
  if (!utilityData) return new FormData();

  let formData = new FormData();
  Object.entries(utilityData).forEach(([key, value]) => {
    if (mainUtilityKeys.includes(key)) {
      formData = appendUtility(value, formData, key);
    }

    if (key === 'other') {
      formData = appendOtherUtility(value, formData, guestyId);
    }
  });

  return formData;
};

export const updateEOWOptions = ({ options, city }) =>
  ['دبي', 'Dubai', 'DXB', 'dubai'].includes(city)
    ? Object.fromEntries(
        Object.entries(options).filter(([, value]) => value.expense_name !== 'ADDC')
      )
    : options;

export const handleIsAccountChange = ({
  key,
  setUtilityData,
  setIsAccountInfo,
  options,
  city,
  dummyUtilityData,
}) => {
  setUtilityData((prevData) => ({
    ...prevData,
    [key]: {
      ...prevData?.key,
      ...dummyUtilityData.emptyOtherObj,
      title: _.capitalize(key),
      utility: key,
      options: handleExpenseOptions(updateEOWOptions({ options, city })),
      expenseName: 'Not set',
      expense_list_id: '0',
      property_id: localStorage.getItem(PROPERTY_ID) || '',
    },
  }));

  setIsAccountInfo((prevObj) => ({
    ...prevObj,
    [key]: true,
  }));
};

export const handleAccountInfoChange = (e, setIsAccountInfo, setUtilityData) => {
  e.persist();
  const sa = e.target.name.split('-');

  if (sa?.[3]) {
    setIsAccountInfo((prevObj) => {
      const idx = sa[3];
      const arr = cloneDeep(prevObj?.[sa[2]]);
      _.set(arr, `[${idx}]`, e.target.value === 'Yes');

      return { ...prevObj, [sa[2]]: arr };
    });
  } else {
    setUtilityData((prevData) => {
      const updatedData = cloneDeep(prevData);
      _.set(updatedData, `${sa[2]}.email`, authService?.currentUser?.name);

      return updatedData;
    });

    setIsAccountInfo((prevObj) => ({
      ...prevObj,
      [sa[2]]: e.target.value === 'Yes',
    }));
  }
};

export const showSwalAlert = ({ title, handleConfim }) => {
  swal({
    title,
    text: 'Are you sure?',
    icon: 'warning',
    buttons: ['No', 'Yes'],
    dangerMode: true,
  }).then((isConfirm) => {
    if (isConfirm) handleConfim();
  });
};

export const handleExpenseChange = (e, setUtilityData) => {
  e.persist();
  const as = e.target.name.split('-');
  const [expenseId, expenseName] = e.target.value.split('-');

  setUtilityData((prevData) => {
    const updatedData = cloneDeep(prevData);

    if (as[2]) {
      const [firstKey, , index] = as;
      _.set(updatedData, `${firstKey}.${index}.${'expenseName'}`, expenseName);
      _.set(updatedData, `${firstKey}.${index}.${'expense_list_id'}`, expenseId);
    } else {
      const [firstKey] = as;
      _.set(updatedData, `${firstKey}.${'expenseName'}`, expenseName);
      _.set(updatedData, `${firstKey}.${'expense_list_id'}`, expenseId);
    }

    return updatedData;
  });
};
