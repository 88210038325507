import React from 'react';
import './UtilityForm.scss';

const UtilityTextBox = ({ name, onChange, value, title, disabled = false, isError }) => {
  if (!name) return <></>;

  return (
    <div className="form-group">
      <label htmlFor={name}>{title}</label>
      <input
        type="text"
        name={name}
        id={name}
        className="form-control"
        onChange={onChange}
        value={value || ''}
        disabled={disabled}
      />
      {isError && <span className="error-message">This field is required</span>}
    </div>
  );
};

export default UtilityTextBox;
