import Axios from 'axios';
import environment from '../environments/environment';
import { authService } from '../services/authService';

Axios.interceptors.request.use(
  (config) => {
    if (config.url.includes(environment.pordalApi)) {
      const token = authService.getUserToken();
      const tokenData = authService.getPordalConfig();

      config.headers.Authorization = `Bearer ${
        tokenData?.access_token ? tokenData?.access_token : token
      }`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);
