/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable import/order */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable no-return-assign */
import React, { useState, useEffect, Fragment, useRef } from 'react';
import DummyUtilityData from 'helpers/dummyData';
import './UtilityForm.scss';
import { ReactComponent as RemoveIcon } from 'assets/ico-remove.svg';
import cn from 'classnames';
import { useWindow } from 'hooks/useWindow';
import FormCard from './Card';
import _, { cloneDeep } from 'lodash';
import { getUtlitiesInfo, saveUtilitiesInfo } from 'services/utilitiesService';
import { authService } from 'services/authService';
import {
  handleAccountInfoChange,
  handleExpenseChange,
  handleExpenseOptions,
  handleIsAccountChange,
  mapUtilitiesDetailsObject,
  paidByOptions,
  prepareUtilityPayload,
  setDataAsPerUtility,
  showSwalAlert,
  updateEOWOptions,
} from './helpers';
import { useLoader } from 'components/common/Loader';
import UtilityTextBox from './UtilityTextBox';
import UtilitySelect from './UtilitySelect';
import { useHistory } from 'react-router-dom';
import UtilityCheckbox from './UtilityCheckbox';
import Button from 'components/common/Button';
import { getContent } from 'services/documentService';
import { PROPERTY, PROPERTY_ID } from 'helpers/const';

const dummyUtilityData = new DummyUtilityData();
const smallScrnpxl = 800;

const UtilityForm = ({
  match: {
    params: { propertyId },
  },
  setBackVisibility,
  updatePropertySummary,
}) => {
  const property = JSON.parse(localStorage.getItem(PROPERTY));
  const onSubmit = (event) => {
    event.preventDefault();
  };
  const prevUtilityDataRef = useRef();

  const history = useHistory();
  
  const [utilityData, setUtilityData] = useState({
    internet: dummyUtilityData.emptyInternetObj,
    'electricity/water': dummyUtilityData.emptyEowObj,
    gas: dummyUtilityData.emptyGasObj,
    chiller: dummyUtilityData.emptyChillerObj,
    other: [dummyUtilityData.emptyOtherObj],
  });

  const [isAccountInfo, setIsAccountInfo] = useState({
    internet: '',
    gas: '',
    'electricity/water': '',
    chiller: '',
  });
  const [utilityRes, setUtilityRes] = useState();
  const [loadingStates, setLoadingStates] = useState({});

  const [activeIndex, setActiveIndex] = useState(0);
  const { width } = useWindow();
  const { setLoaderVisibility } = useLoader();

  const getUtlitiesData = async () => {
    setLoaderVisibility(true);
    const response = await getUtlitiesInfo({
      propertyId,
      ownerId: authService.currentUser.userId,
    });

    if (response?.success) {
      setUtilityRes(response);
      const { expenses = {}, expensesSelect = {} } = response || {};
      const utilityResponse = mapUtilitiesDetailsObject(expenses);
      const { other = [] } = utilityResponse || {};

      const argsArray = [utilityData, utilityResponse, expensesSelect];
      const updatedUtilityData = {
        ...utilityData,
        internet: setDataAsPerUtility('internet', ...argsArray),
        'electricity/water': {
          ...setDataAsPerUtility('electricity/water', ...argsArray),
          options: handleExpenseOptions(
            updateEOWOptions({
              options: expensesSelect?.['electricity/water'],
              city: property?.city,
            })
          ),
        },
        gas: setDataAsPerUtility('gas', ...argsArray),
        chiller: setDataAsPerUtility('chiller', ...argsArray),
        other:
          _.isArray(other) && !_.isEmpty(other)
            ? other?.map((otherObj) => ({
                ...dummyUtilityData.emptyOtherObj,
                ...otherObj,
                expenseName: otherObj?.expenseName || 'Not set',
                expense_list_id: otherObj?.expense_list_id || '0',
                isBill: otherObj?.doc_filename ? 'Yes' : 'No',
                options: handleExpenseOptions(expensesSelect?.other),
              }))
            : [],
      };
      prevUtilityDataRef.current = updatedUtilityData
      setUtilityData(updatedUtilityData);

      setIsAccountInfo((prevData) => ({
        ...prevData,
        internet: utilityResponse?.internet?.account_number ? true : '',
        gas: utilityResponse?.gas?.account_number ? true : '',
        'electricity/water': utilityResponse?.['electricity/water']?.account_number ? true : '',
      }));
    }
    setLoaderVisibility(false);
  };

  const hanldeDownloadClick = async (docObj) => {
    const { doc_type, docId, doc_filename } = docObj;
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [doc_type]: true,
    }));
    await getContent({ docId, doc_filename });
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [doc_type]: false,
    }));
  };

  useEffect(() => {
    getUtlitiesData();
    setBackVisibility(true);
    updatePropertySummary(true);
  }, []);

  const handleButtonClick = (id) => {
    document.getElementById(id).click();
  };

  const handleClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleFileChange = (e) => {
    e.persist();
    const as = e.target.name.split('-');

    setUtilityData((prevData) => {
      const updatedData = cloneDeep(prevData);

      const [firstKey, secondKey, index] = as;
      _.set(updatedData, `${firstKey}.${index}.${secondKey}`, e.target.files[0]);

      return updatedData;
    });
  };

  const handleChange = (e) => {
    e.persist();
    const as = e.target.name.split('-');

    setUtilityData((prevData) => {
      const path = as[2] ? `${as[0]}.${as[2]}.${as[1]}` : `${as[0]}.${as[1]}`;

      return _.set({ ...prevData }, path, e.target.value);
    });
  };

  const handleProviderChange = (e) => {
    e.persist();
    const as = e.target.name.split('-');

    setUtilityData((prevData) => {
      const updatedData = cloneDeep(prevData);

      if (as[2]) {
        const [firstKey, secondKey, index] = as;
        _.set(updatedData, `${firstKey}.${index}.${secondKey}`, e.target.value);
      } else {
        const [firstKey, secondKey] = as;
        _.set(updatedData, `${firstKey || key}.${secondKey}`, e.target.value);
      }

      return updatedData;
    });
  };

  const handleAddingOtherSection = () => {
    setUtilityData((prevData) => ({
      ...prevData,
      other: [
        ...prevData?.other,
        {
          ...dummyUtilityData.emptyOtherObj,
          utility: 'other',
          expenseName: 'Not set',
          expense_list_id: '0',
          options: handleExpenseOptions(utilityRes?.expensesSelect?.other),
          isBill: 'No',
          property_id: localStorage.getItem(PROPERTY_ID),
        },
      ],
    }));
  };

  const handleRemovingOtherSection = (idx, title) => {
    showSwalAlert({
      title: `Deleting Utility: ${title && title !== 'Not set' ? title : 'New Utility'}`,
      handleConfim: () => {
        setUtilityData((prevData) => {
          const updatedOther = [...prevData.other];
          updatedOther.splice(idx, 1);

          return { ...prevData, other: updatedOther };
        });
      },
    });
  };

  const isValidExpenses = (key, expenseName, account_number) => {
    if (key !== 'other') {
      if (!_.isEmpty(_.trim(account_number)) && _.isEqual(expenseName, 'Not set')) return false;
    } else if (_.isArray(utilityData?.other) && utilityData?.other?.length > 0) {
      for (const data of utilityData?.other) {
        if (!_.isEmpty(_.trim(data?.account_number)) && _.isEqual(data?.expenseName, 'Not set'))
          return false;
      }
    }

    return true;
  };


  const validateFormData = () => {
    for (const key in utilityData) {
      const { account_number = '', email = '', expenseName = '' } = utilityData[key];
      const noValidateUtility = ['chiller', 'other'].includes(key);

      if (!isValidExpenses(key, expenseName, account_number)) return false;
      if (!noValidateUtility && _.isBoolean(isAccountInfo[key])) {
        if (
          _.isEmpty(_.trim(account_number)) &&
          !_.isEqual(expenseName, 'Not set') &&
          isAccountInfo[key]
        ) {
          return false;
        }
        if (_.isEmpty(_.trim(email)) && !isAccountInfo[key]) return false;
      }
    }

    return true;
  };

  // Submittting Form...
  const handleSubmit = async () => {
    if (!_.isEqual(utilityData, prevUtilityDataRef.current)) {
      const isValidUtilityData = validateFormData();
      if (isValidUtilityData) {
        setLoaderVisibility(true);
        const formData = prepareUtilityPayload(utilityData, propertyId);
        await saveUtilitiesInfo({
          formData,
          propertyId,
          ownerId: authService.currentUser.userId,
        });
        setLoaderVisibility(false);
        history.push(`/settings/summary/${propertyId}`);
      }
    }
  };

  return (
    <>
      <form onSubmit={onSubmit} className="utility-form-container">
        <div className={cn('form-card-wrapper', 'utility-questions-wrapper')}>
          {utilityData &&
            Object.entries(utilityData).map(([key, val], idx) => {
              const data = val;
              const shouldDisabledAll =
                data?.expenseName === 'Not set' && data?.expense_list_id === '0';
              const isError =
                key !== 'chiller' &&
                data?.expense_list_id !== '0' &&
                _.isEmpty(_.trim(data?.account_number));

              if (Array.isArray(data)) {
                return data?.map((otherObj, innerIdx) => {
                  const shouldDisabledAllOther =
                    otherObj?.expenseName === 'Not set' && otherObj?.expense_list_id === '0';

                  return (
                    <Fragment key={`${(4 + innerIdx + 1).toString()}`}>
                      <FormCard>
                        <p
                          onClick={() =>
                            handleRemovingOtherSection(innerIdx, otherObj?.expenseName)
                          }
                          className="remove-icon-wrapper"
                        >
                          <RemoveIcon />
                        </p>
                        <div className="question-plus-minus-wrapper">
                          <h3
                            className={cn('form-card-title')}
                            onClick={() => width <= smallScrnpxl && handleClick(innerIdx + 4)}
                          >
                            {width <= smallScrnpxl ? (
                              <div
                                className={cn('plusminus', 'expand-collapse', {
                                  active: activeIndex === innerIdx + 4,
                                })}
                              />
                            ) : (
                              <></>
                            )}
                            <p>Other</p>
                          </h3>
                        </div>
                        <div className={cn('answer', { opened: activeIndex === innerIdx + 4 })}>
                          <>
                            <UtilitySelect
                              options={otherObj?.options}
                              name={`other-expenseName-${innerIdx}`}
                              selectedValue={`${otherObj?.expense_list_id}-${otherObj?.expenseName}`}
                              onChange={(e)=>handleExpenseChange(e,setUtilityData)}
                              title="Select Provider"
                            />

                            <UtilityTextBox
                              name={`${otherObj?.utility}-account_number-${innerIdx}`}
                              onChange={handleProviderChange}
                              value={otherObj?.account_number}
                              title="Enter Account Number"
                              disabled={shouldDisabledAllOther}
                            />
                            <UtilitySelect
                              options={paidByOptions}
                              name={`${otherObj?.utility}-paid_by-${innerIdx}`}
                              selectedValue={otherObj?.paid_by}
                              onChange={handleProviderChange}
                              title="Paid By"
                              disabled={shouldDisabledAllOther}
                            />

                            <UtilityTextBox
                              name={`${otherObj?.utility}-login-${innerIdx}`}
                              onChange={handleProviderChange}
                              value={otherObj?.login}
                              title="Web Login"
                              disabled={shouldDisabledAllOther}
                            />
                            <UtilityTextBox
                              name={`${otherObj?.utility}-pass-${innerIdx}`}
                              onChange={handleProviderChange}
                              value={otherObj?.pass}
                              title="Web Password"
                              disabled={shouldDisabledAllOther}
                            />

                            {otherObj?.paid_by === '0' ? (
                              <>
                                {otherObj?.isBill !== 'Yes' && (
                                  <>
                                    <UtilityTextBox
                                      name={`${otherObj?.utility}-bill_link-${innerIdx}`}
                                      onChange={handleProviderChange}
                                      value={otherObj?.bill_link}
                                      title=" Enter a Link"
                                      disabled={shouldDisabledAllOther}
                                    />
                                    <p
                                      style={{
                                        margin: 'auto',
                                        textAlign: 'center',
                                        padding: '3px 0',
                                      }}
                                    >
                                      OR
                                    </p>
                                  </>
                                )}

                                <div className="radio form-group">
                                  <label
                                    htmlFor="select-bill-option"
                                    style={{ fontWeight: '400', color: '#23282c' }}
                                  >
                                    Do you want to upload a Bill?
                                  </label>
                                  <UtilityCheckbox
                                    name={`${otherObj?.utility}-isBill-${innerIdx}`}
                                    id={`${otherObj?.utility}-Yes-${innerIdx}`}
                                    onChange={handleChange}
                                    value="Yes"
                                    checked={otherObj?.isBill === 'Yes'}
                                    disabled={shouldDisabledAllOther}
                                  />
                                  <UtilityCheckbox
                                    name={`${otherObj?.utility}-isBill-${innerIdx}`}
                                    id={`${otherObj?.utility}-No-${innerIdx}`}
                                    onChange={handleChange}
                                    value="No"
                                    checked={otherObj?.isBill === 'No'}
                                    disabled={shouldDisabledAllOther}
                                  />
                                </div>

                                {otherObj?.isBill === 'Yes' ? (
                                  <div className="form-group">
                                    <label htmlFor={`${otherObj?.utility}-bill-${innerIdx}`}>
                                      Select Document
                                    </label>
                                    {(otherObj?.bill && otherObj?.bill?.name) ||
                                    otherObj?.doc_filename ? (
                                      <p
                                        style={{
                                          fontSize: '12px',
                                          margin: 'unset',
                                          color: '#AD8C63',
                                        }}
                                      >
                                        {`${otherObj?.bill?.name || otherObj?.doc_filename || ''}`}
                                      </p>
                                    ) : (
                                      <></>
                                    )}
                                    <button
                                      type="button"
                                      className={cn('file-input-button', 'form-control')}
                                      onClick={() =>
                                        handleButtonClick(`${otherObj?.utility}-bill-${innerIdx}`)
                                      }
                                      disabled={shouldDisabledAllOther}
                                    >
                                      <input
                                        id={`${otherObj?.utility}-bill-${innerIdx}`}
                                        name={`${otherObj?.utility}-bill-${innerIdx}`}
                                        type="file"
                                        hidden
                                        onChange={handleFileChange}
                                        accept="image/*, application/pdf"
                                        disabled={shouldDisabledAllOther}
                                      />
                                      Choose file
                                    </button>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                {otherObj?.docId && (
                                  <Button
                                    style={{ width: '100%', padding: '5px' }}
                                    variant="outline"
                                    value={
                                      loadingStates[otherObj?.doc_type] ? 'Downloading' : 'Download'
                                    }
                                    onClick={() => hanldeDownloadClick(otherObj)}
                                    disabled={loadingStates[otherObj?.doc_type]}
                                  />
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        </div>
                      </FormCard>
                    </Fragment>
                  );
                });
              }

              return (
                <FormCard key={key}>
                  <div className="question-plus-minus-wrapper">
                    <h3 className={cn('form-card-title')} onClick={() => handleClick(idx)}>
                      {width <= smallScrnpxl ? (
                        <div
                          className={cn('plusminus', 'expand-collapse', {
                            active: activeIndex === idx,
                          })}
                        />
                      ) : (
                        <></>
                      )}
                      <div>{_.capitalize(data?.utility || key)}</div>
                    </h3>
                  </div>
                  <div className={cn('answer', { opened: activeIndex === idx })}>
                    {!data?.expenseName && !data?.account_number && !isAccountInfo[key] ? (
                      <>
                        <div className="radio form-group">
                          <label
                            htmlFor="select-bill-option"
                            style={{ fontWeight: '400', color: '#23282c' }}
                          >
                            Do you already have an account?
                          </label>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              name={`isAccount-Yes-${key}`}
                              type="radio"
                              onChange={() => {
                                showSwalAlert({
                                  title: `Existing ${_.capitalize(key)} Account`,
                                  handleConfim: () => {
                                    handleIsAccountChange({
                                      key,
                                      setUtilityData,
                                      setIsAccountInfo,
                                      options: utilityRes?.expensesSelect?.[key],
                                      city: property?.city,
                                      dummyUtilityData,
                                    });
                                  },
                                });
                              }}
                              id={`isAccount-Yes-${key}`}
                              value="Yes"
                              checked={!!isAccountInfo?.[key]}
                            />
                            <label htmlFor={`isAccount-Yes-${key}`} className="form-check-label">
                              Yes
                            </label>
                          </div>
                          <UtilityCheckbox
                            name={`isAccount-No-${key}`}
                            onChange={(e)=>handleAccountInfoChange(e,setIsAccountInfo,setUtilityData)}
                            value="No"
                            checked={isAccountInfo?.[key] === false}
                            disabled={shouldDisabledAll}
                            label="No - please organize this for me"
                          />
                        </div>

                        {isAccountInfo?.[key] === false && (
                          <>
                            <UtilityTextBox
                              name={`${data?.utility || key}-email`}
                              onChange={handleProviderChange}
                              value={data?.email}
                              title="Email"
                            />
                            <p style={{ fontSize: '10px', marginTop: '5px' }}>
                              Please upload your documents in the document section so we can proceed
                              with the application
                            </p>
                            <UtilitySelect
                              options={paidByOptions}
                              name={`${data?.utility || key}-paid_by`}
                              selectedValue={data?.paid_by}
                              onChange={handleProviderChange}
                              title="Paid By"
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <UtilitySelect
                          options={data?.options}
                          name={`${data?.utility}-expenseName`}
                          selectedValue={`${data?.expense_list_id}-${data?.expenseName}`}
                          onChange={(e)=>handleExpenseChange(e,setUtilityData)}
                          title="Select Provider"
                        />

                        <UtilityTextBox
                          name={`${data?.utility}-account_number`}
                          onChange={(e) => handleProviderChange(e, key)}
                          value={data?.account_number}
                          title="Enter Account Number"
                          disabled={shouldDisabledAll}
                          isError={isError}
                        />
                        <UtilitySelect
                          options={paidByOptions}
                          name={`${data?.utility}-paid_by`}
                          selectedValue={data?.paid_by}
                          onChange={handleProviderChange}
                          title="Paid By"
                          disabled={shouldDisabledAll}
                        />
                        <UtilityTextBox
                          name={`${data?.utility}-login`}
                          onChange={handleProviderChange}
                          value={data?.login}
                          title="Web Login"
                          disabled={shouldDisabledAll}
                        />
                        <UtilityTextBox
                          name={`${data?.utility}-pass`}
                          onChange={handleProviderChange}
                          value={data?.pass}
                          title="Web Password"
                          disabled={shouldDisabledAll}
                        />
                      </>
                    )}
                  </div>
                </FormCard>
              );
            })}
          <div className="add-utility-btn-wrapper">
            <button className="btn" type="button" onClick={handleAddingOtherSection}>
              Add New Utility
            </button>
          </div>
        </div>

        <div className="form-footer-container">
          <button className="btn submit-utility-btn" type="button" onClick={handleSubmit}>
            SAVE SECTION
          </button>
        </div>
      </form>
    </>
  );
};

export default UtilityForm;
