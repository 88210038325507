/* eslint-disable newline-before-return */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import _ from 'lodash';
import './ComponentSlider.scss';

class ComponentSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marginLeft: 0,
    };

    this.handleResize = _.debounce(this.resetMargin, 200);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.resetMargin();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  resetMargin = () => {
    if (this.slider && this.sliderContent) {
      this.setState({ marginLeft: 0 });
    }
  };

  handleLeftClicked = () => {
    const currentMarginLeft = this.state.marginLeft;
    const sliderWidth = this.slider.offsetWidth;
    let marginLeft;

    if (currentMarginLeft > sliderWidth) {
      marginLeft = currentMarginLeft - sliderWidth;
    } else {
      marginLeft = 0;
    }

    this.setState({ marginLeft });
  };

  handleRightClicked = () => {
    const currentMarginLeft = this.state.marginLeft;
    const sliderWidth = this.slider ? this.slider.offsetWidth : 0;
    const contentWidth = this.sliderContent ? this.sliderContent.offsetWidth : 0;
    const remainingWidth = contentWidth - (sliderWidth - 20) - currentMarginLeft;
    let marginLeft;

    if (remainingWidth > 0) {
      if (remainingWidth <= sliderWidth) {
        marginLeft = currentMarginLeft + remainingWidth;
      } else {
        marginLeft = currentMarginLeft + sliderWidth;
      }
    } else {
      marginLeft = currentMarginLeft;
    }

    this.setState({ marginLeft });
  };

  renderLeftArrow = () => {
    if (this.state.marginLeft !== 0) {
      return (
        <button className="caret caret-left" onClick={this.handleLeftClicked}>
          {this.props.renderLeftArrow()}
        </button>
      );
    }
    return null;
  };

  renderRightArrow = () => {
    const currentMarginLeft = this.state.marginLeft;
    const sliderWidth = this.slider ? this.slider.offsetWidth : 0;
    const contentWidth = this.sliderContent ? this.sliderContent.offsetWidth : 0;
    const remainingWidth = contentWidth - (sliderWidth - 20) - currentMarginLeft;

    if (remainingWidth > 0) {
      return (
        <button className="caret caret-right" onClick={this.handleRightClicked}>
          {this.props.renderRightArrow()}
        </button>
      );
    }
    return null;
  };

  render() {
    return (
      <div className="component-slider" ref={(el) => (this.slider = el)}>
        {this.renderLeftArrow()}
        <div className="slider-container">
          <div
            className="slider-content"
            ref={(el) => (this.sliderContent = el)}
            style={{ marginLeft: `-${this.state.marginLeft}px` }}
          >
            {this.props.children}
          </div>
        </div>
        {this.renderRightArrow()}
      </div>
    );
  }
}

ComponentSlider.displayName = 'ComponentSlider';
ComponentSlider.defaultProps = {
  renderLeftArrow: () => <span>←</span>,
  renderRightArrow: () => <span>→</span>,
};

export default ComponentSlider;
