import Axios from 'axios';
// import _ from 'lodash';
import environment from '../environments/environment';

function modifyURL(baseURL) {
  const parts = baseURL.split('/');
  const index = _.findIndex(parts, (part) => part === 'api');

  if (index !== -1 && index < parts.length - 1) {
    parts.splice(index + 1, 0, 'v2');
    const modifiedURL = parts.join('/');

    return modifiedURL;
  }
}

export const getUtlitiesInfo = (props) =>
  new Promise((resolve) => {
    getUtilities(props)
      .then((item) => {
        if (item.data) {
          resolve(item?.data);
        } else {
          resolve();
        }
      })
      .catch(() => {
        resolve();
      });
  });

export const saveUtilitiesInfo = (props) =>
  new Promise((resolve) => {
    utilitiesPost(props)
      .then((item) => {
        if (item.data.success) {
          resolve(item.data);
        } else {
          resolve(null);
        }
      })
      .catch(() => {
        resolve(null);
      });
  });

const getUtilities = ({ ownerId, propertyId }) => {
  const modifiedURL = modifyURL(environment.ownerApi);

  return Axios.get(`${modifiedURL}/owner_utilities/`, {
    params: { ownerId, propertyId },
  });
};

const utilitiesPost = ({ ownerId, propertyId, formData }) => {
  const modifiedURL = modifyURL(environment.ownerApi);

  return Axios.post(
    `${modifiedURL}/owner_utilities/?ownerId=${ownerId}${
      propertyId ? `&propertyId=${propertyId}` : ``
    }`,
    formData
  );
};
