/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFieldName } from '../../../helpers/fieldHelper';

import './Radio.scss';

const Radio = ({ index, fieldState, name, handleChange, field, disabled, form, errorMessage }) => {
  const { t } = useTranslation('form');
  const isIbanNotStartWithAE =
    form?.bankAccountDetails?.iban && !form?.bankAccountDetails?.iban?.startsWith('AE');

  const isAmericanSwiftCode =
    form?.bankAccountDetails?.swiftCode &&
    !form?.bankAccountDetails?.iban &&
    form?.bankAccountDetails?.swiftCode?.startsWith('BOFAUS');

  const handleIban = (key) => key === 'AED' && (isIbanNotStartWithAE || isAmericanSwiftCode);
  const error = errorMessage || '';

  return (
    <div className="radio form-group">
      <label>{`${index}. ${t(name)}`}</label>
      {field.options.map((option, i) => (
        <div className="form-check" key={`${index}-${t(name)}-${option.key}`}>
          <input
            className="form-check-input"
            name={name}
            type="radio"
            onChange={handleChange}
            id={`${name}${i}`}
            value={option.key}
            checked={
              isIbanNotStartWithAE && fieldState === 'AED' ? false : fieldState === option.key
            }
            disabled={disabled || handleIban(option.key)}
          />
          <label htmlFor={`${name}${i}`} className="form-check-label">
            {t(getFieldName(option.value))}
          </label>
        </div>
      ))}
      {error && <span style={{ fontSize: '14px', color: 'red' }}>{error}</span>}
    </div>
  );
};

export default Radio;
