/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import { ReactComponent as BackIcon } from 'assets/back.svg';
import { ReactComponent as EditIcon } from 'assets/ico-edit.svg';
import { ReactComponent as NextIcon } from 'assets/nextTriangle.svg';
import classNames from 'classnames';
import Button from 'components/common/Button';
import { useLoader } from 'components/common/Loader';
import { PROPERTY } from 'helpers/const';
import { useSurvey } from 'hooks/useSurvey';
import { SurveySummary } from 'hooks/useSurvey/models';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { authService } from 'services/authService';
import {
  getDocList,
  getDocumentFile,
  getNocForm,
  saveUploadedDocument,
} from 'services/documentService';
import { getSurveyGetV2 } from 'services/surveyService';

import BankDetails from './components/BankDetails';
import { Detail } from './components/Details';
import { DocDetails } from './components/DocDetails';
import UtilityTitle, {
  transformSurveyBankData,
  UtilityCard,
  UtilityData,
} from './components/UtilityDetails';
import { PropertySummaryProps } from './models';
import PropertyModal from './PropertyModal';
import style from './style.module.scss';

const PropertySummary: FC<PropertySummaryProps> = ({
  match: {
    params: { propertyId },
  },
  setBackVisibility,
  updatePropertySummary,
}) => {
  // eslint-disable-next-line
  const { t } = useTranslation('form');
  const history = useHistory();
  const [summary, setSummary] = useState<SurveySummary>();
  const [doc_type_id, setDocTypeId] = useState('1');
  const [doc_type, setDoc_type] = useState('');
  const [expires_at, setExpiresAt] = useState('');
  const [issue_date, setIssueDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [doc_file, setDoc_file] = useState<any>(null);
  const [documentList, setDocumentList] = useState<any>([]);
  const [selectedDocObj, setSelectedDocObj] = useState<any>(null);
  const [loadingStates, setLoadingStates] = useState({});
  const { getSurveySummary, mapSurveyForSummary } = useSurvey();
  const { setLoaderVisibility } = useLoader();
  const [property, setProperty] = useState({});

  const getDocData = async () => {
    const response = await getDocList({ propertyId, ownerId: authService.currentUser.userId });
    if (response?.success) setDocumentList(response?.docList);
  };

  useEffect(() => {
    localStorage.removeItem(PROPERTY);

    const fetchData = async () => {
      setLoaderVisibility(true);
      setBackVisibility(true);

      try {
        const [survey, _] = await Promise.all([getSurveySummary({ propertyId }), getDocData()]);

        const getSurveyV2Result = await getSurveyGetV2({
          ownerId: authService.currentUser.userId,
          propertyId,
        });

        const sections = getSurveyV2Result?.data?.surveyData?.sections;
        const surveyData = {
          ...survey,
          ...(Array.isArray(sections) &&
            sections.length > 0 && {
              propertyBankAccount: {
                id: survey?.propertyBankAccount?.id || '',
                owner_id: survey?.propertyBankAccount?.owner_id || '',
                property_id: survey?.propertyBankAccount?.property_id || '',
                transfer_payout: survey?.propertyBankAccount?.transfer_payout || '',
                ...transformSurveyBankData(sections),
              },
            }),
        };
        setSummary(mapSurveyForSummary(sections ? surveyData : survey));
        setProperty(survey?.property);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoaderVisibility(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId]);

  const onBackClick = () => {
    setBackVisibility(false);
    history.push(`/settings/list/${propertyId}`);
  };

  const openModal = (data: any) => {
    setIsModalOpen(true);
    setSelectedDocObj(data);
    setDoc_type(data.doc_type);
    setDocTypeId(data?.doc_type_id);
    setExpiresAt(data?.expires_at || '');
    if (data.doc_type === 'DEWA Bill') {
      const issueDate = data?.expires_at ? moment().subtract(50, 'days').format('YYYY-MM-DD') : '';
      setIssueDate(issueDate);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDocObj(null);
  };

  const handleFileChange = (e: any) => setDoc_file(e.target.files[0]);

  const handleExpiryChange = (e: any) => setExpiresAt(e.target.value);

  const handleIssueDate = (e: any) => {
    if (expires_at !== '' && e.target.value === '') {
      return;
    }
    setIssueDate(e.target.value);
    const expiry = moment(e.target.value).add(90, 'days').format('YYYY-MM-DD');
    setExpiresAt(expiry);
  };

  const handleButtonClick = () => {
    const doc = document.getElementById('document');
    if (doc !== null) doc.click();
  };

  const resetForm = () => {
    setDoc_file(null);
    setDocTypeId('');
    setExpiresAt('');
    setIssueDate('');
    setSelectedDocObj(null);
  };

  const onSendClick = async () => {
    if (doc_file || expires_at !== selectedDocObj?.expires_at) {
      setLoaderVisibility(true);
      const formData: any = new FormData();
      formData.append('ownerDocId', selectedDocObj?.ownerDocId);
      formData.append('doc_type_id', doc_type_id);
      formData.append('expires_at', expires_at);
      formData.append('doc_file', doc_file);
      formData.append('is_file', typeof doc_file === 'object' && doc_file ? '1' : '0');
      formData.append('propertyId', selectedDocObj?.property_id);
      formData.append('ownerId', selectedDocObj?.owner_id);
      closeModal();
      await saveUploadedDocument(formData);
      resetForm();
      await getDocData();
      setLoaderVisibility(false);
    }
  };
  const handleUploadClick = (docData: any) => openModal(docData);

  const hanldeDownloadClick = async (docData: any, isNoc = false) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [docData.doc_type]: true,
    }));

    if (isNoc) await getNocForm();
    else await getDocumentFile(docData?.ownerDocId, docData?.file_name);

    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [docData.doc_type]: false,
    }));
  };

  if (!summary) {
    return null;
  }

  return (
    <>
      <div className={style.wrapper}>
        <header className={style.header}>
          <Button
            prefix={<BackIcon />}
            variant="outline"
            className={style.backButton}
            value={t('back') as string}
            onClick={onBackClick}
          />
          <h1 className={style.nickname}>{summary.nickname}</h1>
        </header>
        <section className={style.section}>
          <h2 className={style.sectionHeader}>{t('propertyDetails')}</h2>
          <div className={classNames(style.row)} style={{ gridTemplateColumns: 'auto' }}>
            <div className={classNames(style.col, style.fill)} style={{ width: '100%' }}>
              <UtilityTitle title={t('fullAddressOfProperty')} />
              <Detail detail={summary.address_2 || summary.address} />
            </div>
          </div>
          <div className={style.row}>
            <div className={style.col}>
              <UtilityTitle title={t('parkingNumber')} />
              <Detail detail={summary.parkingNumber} />
            </div>
            <div className={style.col}>
              <UtilityTitle title={t('permitCode')} />
              <Detail detail={summary.permit} />
            </div>
            <div className={style.col}>
              <UtilityTitle title={t('permitExpiry')} />
              <Detail detail={summary.permitExpiry} />
            </div>
            <div className={style.col}>
              <UtilityTitle title={t('wiFiName')} />
              <Detail detail={summary.wifiName} />
            </div>
            <div className={style.col}>
              <UtilityTitle title={t('wiFiPassword')} />
              <Detail detail={summary.wifiPass} />
            </div>
          </div>
        </section>
        {/* UTILITIES */}
        <section className={style.section}>
          <h2 className={style.sectionHeader}>
            {t('form:utilitiesDetails')}
            <EditIcon
              style={{ margin: '0 10px 10px', cursor: 'pointer' }}
              onClick={() => {
                localStorage.setItem(PROPERTY, JSON.stringify(property));
                updatePropertySummary(true);
                history.push({
                  pathname: `/settings/property-summary/${propertyId}/update-utility-details`,
                });
              }}
            />
          </h2>
          <div className={classNames(style.row, style.utilitiesDetailsWrapper)}>
            <UtilityCard name="internet" summary={summary} />
            <UtilityCard name="eow" summary={summary} />
            <UtilityCard name="gas" summary={summary} />
            <UtilityCard name="chiller" summary={summary} />
            {summary?.other?.map((data, idx) => (
              <div className={style.col} key={(idx + 1).toString()}>
                <UtilityTitle title="other" paidBy={data?.paid_by} />
                <UtilityData type="other" summary={data} other />
              </div>
            ))}
          </div>
        </section>
        {/* BANK DETAILS */}
        <section className={style.section}>
          <h2 className={style.sectionHeader}>
            {t('form:bankDetails')}
            <EditIcon
              style={{ margin: '0 10px 10px', cursor: 'pointer' }}
              onClick={() => {
                updatePropertySummary(true);
                history.push(`/settings/property-survey/${propertyId}/2`);
              }}
            />
          </h2>
          <div className={classNames(style.row, style.utilitiesDetailsWrapper)}>
            <BankDetails title="accountName" summary={summary} name="accountName" />
            <BankDetails title="bankName" summary={summary} name="bankName" />
            <BankDetails title="currency" summary={summary} name="currency" />
          </div>

          <BankDetails title="iban" summary={summary} name="iban" fullRow />

          <BankDetails title="accountNo" summary={summary} name="accountNumber" fullRow />

          <BankDetails title="swiftCode" summary={summary} name="swift" fullRow />
          <div
            className={classNames(style.row, style.utilitiesDetailsWrapper)}
            style={{ gridTemplateColumns: 'auto' }}
          >
            <div className={classNames(style.col)}>
              <UtilityTitle title={t('bankAddress')} />
              <div>
                <span>{summary.bankData.address || '-'}</span>
              </div>
            </div>
          </div>
          <div className={classNames(style.row, style.utilitiesDetailsWrapper)}>
            <BankDetails title="sortCode" summary={summary} name="sortCode" />
            <BankDetails title="routeCode" summary={summary} name="routeCode" />
          </div>
        </section>

        <section className={style.section}>
          <h2 className={style.sectionHeader}>Documents</h2>
          <div className={classNames(style.row, style.utilitiesDetailsWrapper)}>
            {Array.isArray(documentList) &&
              documentList?.length > 0 &&
              documentList?.map((docData, idx) => {
                let shouldHideExpiry = false;
                if (['NOC', 'Title Deed'].includes(docData?.doc_type)) shouldHideExpiry = true;

                return (
                  <div
                    key={`${docData?.doc_type + idx}`}
                    className={style.col}
                    style={{ position: 'relative' }}
                  >
                    <UtilityTitle title={docData?.doc_type} />
                    <DocDetails
                      docDetails={docData}
                      shouldHideExpiry={shouldHideExpiry}
                      onUploadClick={() => handleUploadClick(docData)}
                      onDownloadClick={() => hanldeDownloadClick(docData)}
                      isDownloading={loadingStates[docData?.doc_type]}
                      getNocForm={() => hanldeDownloadClick(docData, true)}
                    />
                  </div>
                );
              })}
          </div>
        </section>
      </div>

      <PropertyModal
        isOpen={isModalOpen}
        onClose={closeModal}
        doc_type={doc_type}
        selectedDocObj={selectedDocObj}
        handleButtonClick={handleButtonClick}
        onSendClick={onSendClick}
        expires_at={expires_at}
        issue_date={issue_date}
        doc_file={doc_file}
        handleFileChange={handleFileChange}
        handleExpiryChange={handleExpiryChange}
        setDocTypeId={setDocTypeId}
        handleIssueDate={handleIssueDate}
      />
    </>
  );
};

export default PropertySummary;
